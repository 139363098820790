<template>
  <div class="page-content">
    <h1 class="page-content__title">Button</h1>
    <p class="page-content__description">
    Кнопки используются для выполнения разных действий в интерфейсе и бывают разных видов в зависимости от их приоритета и действия, которые они выполняют.
    </p>
    <h2 class="page-content__title">Основная кнопка</h2>
    <p>Основная кнопка на экране или в смысловом блоке может использоваться со стандартной кнопкой с заливкой или со стандартной кнопкой с обводкой. Бывает разных размеров: L, M, S, XS и разных видов.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с текстом</h3>
    <p>В сервисах Доставки используется в саммари в корзине и чекауте, в формах (например, в ЛК), в модальных окнах.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с иконкой перед текстом</h3>
    <p>В сервисах Доставки используется в саммари в корзине и чекауте, в формах (например, в ЛК), в модальных окнах.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с иконкой после текста</h3>
    <p>В сервисах Доставки используется в саммари в корзине и чекауте, в формах (например, в ЛК), в модальных окнах.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с текстом в две строчки</h3>
    <p>В сервисах Доставки используется в саммари в корзине (на iOS и Android)</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с текстом в одну и две строчки</h3>
    <p>В сервисах Доставки используется в саммари в корзине и чекауте, в формах (например, в ЛК), в модальных окнах.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Платёжные кнопки</h3>
    <p>В сервисах Доставки используется в саммари в корзине и чекауте, в формах (например, в ЛК), в модальных окнах.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка-счётчик</h3>
    <p>В сервисах Доставки используется в саммари в корзине (на iOS и Android)</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка-иконка</h3>
    <p>В сервисах Доставки используется в саммари в корзине и чекауте, в формах (например, в ЛК), в модальных окнах.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>

<h2 class="page-content__title">Стандартная кнопка с заливкой</h2>
    <p>В сервисах Доставки используется с основной кнопкой (на iOS и Android). Бывает разных размеров: L, M, S, XS и разных видов.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с текстом</h3>
    <p>Используется в шторках</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с текстом в две строчки</h3>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с иконкой перед текстом</h3>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с иконкой после текста</h3>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка-иконка</h3>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>

<h2 class="page-content__title">Стандартная кнопка с обводкой</h2>
    <p>В сервисах Доставки используется с основной кнопкой в модальных окнах, на карточках товара в каталоге и поиске. Бывает двух размеров: M, S.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    
  <h2 class="page-content__title">Акцентная кнопка-таймер</h2>
    <p>В сервисах Доставки используется в истории заказов (таймер в заменах). Бывает только одного размера, M.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    
<h2 class="page-content__title">Вспомогательная кнопка с обводкой</h2>
    <p>В сервисах Доставки используется на вебе — в фильтрах, в пагинации, в счётчике в корзине и в слайдере на главной странице. Бывает разных размеров: M, S и разных видов.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с текстом или цифрой</h3>
    <p>В сервисах Доставки используется в пагинации (кнопка «Показать ещё», кнопка с номером страницы)</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с иконкой после текста</h3>
    <p>В сервисах Доставки используется в пагинации (кнопка «Вперёд»), в фильтрах каталога и поиска</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка с иконкой перед текстом</h3>
    <p>В сервисах Доставки используется в пагинации (кнопка “Назад”)</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
   <h3 class="page-content__title">Кнопка-счётчик</h3>
    <p>В сервисах Доставки используется в корзине в вебе</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>
    <h3 class="page-content__title">Кнопка-иконка</h3>
    <p>В сервисах Доставки используется в листинге товаров на главной в вебе</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4932%253A60749" allowfullscreen></iframe>

  </div>
</template>

<script>
export default {
  name: 'Button',
};
</script>

<style lang="scss" scoped>
</style>